import {timeFormat,numberFormat} from "@custom";

export const tableColumn = [
    {
        label: "序号",
        type: "index",
        width: 100
    },
    /*{
        prop: "businessModule",
        label: "操作模块",
    },*/
    {
        prop: "operater",
        label: "操作人"
    },
    {
        prop: "operateTime",
        label: "操作时间",
        render: (h, {row}) => {
            const operateTime = ! row.operateTime ? "" : timeFormat(new Date(row.operateTime), 'yyyy-MM-dd HH:mm:ss');
            return h("span", operateTime);
        }
    },
    {
        prop: "operateModule",
        label: "操作模块"
    },
    {
        prop: "operateType",
        label: "操作类型"
    },
    /*{
        prop: "record",
        label: "操作记录"
    }*/
];

