<template>
    <section class="log-management padding_10_15">
        <div class="top-bar bg-white">
            <el-select v-model="formSearch.operateModule" placeholder="请选择操作模块" style="margin-right: 10px" size="small">
                <el-option label="房间" value="房间"/>
                <el-option label="账单" value="账单"/>
                <el-option label="合同" value="合同"/>
                <el-option label="工单" value="工单"/>
                <el-option label="发布" value="发布"/>
                <el-option label="资产" value="资产"/>
                <el-option label="密码" value="密码"/>
            </el-select>
            <el-select v-model="formSearch.operateType" placeholder="请选择操作类型" style="margin-right: 10px" size="small">
                <el-option label="add" value="add"/>
                <el-option label="update" value="update"/>
            </el-select>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLogs" :columns="tableColumn" :query="formSearch" :height="830">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="120">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="xiangQing(row)">详情</span>
                    </div>
                </template>
          </el-table-column>
        </r-e-table>
        <r-e-dialog title="详情" :visible.sync="detailShow" top="5vh" width="600px">
            <el-form ref="formPublish" label-width="100px" size="small">
                <el-form-item label="操作人">
                    <el-input v-model="detailData.operater" disabled />
                </el-form-item>
                <el-form-item label="操作时间">
                    <el-date-picker v-model="detailData.operateTime" disabled type="datetime" placeholder="选择日期时间" />
                </el-form-item>
                <el-form-item label="操作模块">
                    <el-input v-model="detailData.operateModule" disabled />
                </el-form-item>
                <el-form-item label="操作类型">
                    <el-input v-model="detailData.operateType" disabled />
                </el-form-item>

                <el-form-item label="旧数据">
                    <el-input v-model="detailData.oldData" type="textarea" disabled :autosize="{ minRows: 2, maxRows: 4}" />
                </el-form-item>
                <el-form-item label="新数据">
                    <el-input v-model="detailData.newData" type="textarea" disabled :autosize="{ minRows: 2, maxRows: 4}" />
                </el-form-item>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
    import { tableColumn } from "./data";
    import { getOperationLogList } from "@/api/log";
    export default {
        name: "operation-log",
        data() {
            return {
                tableColumn,
                tableData: [],
                formSearch: {
                    operateModule: null,
                    operateType: null,
                },
                detailShow: false,
                detailData: {}
            };
        },
        components:{
        },
        mounted() {

        },
        methods: {
            getLogs(params) {
                return getOperationLogList(params);
            },
            handleSearch() {
                this.$refs["orderTableRef"].getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            xiangQing(data) {
                this.detailData = {...data};
                this.detailShow = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .log-management {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>